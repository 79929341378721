import * as React from 'react'
import { withTheme } from 'emotion-theming'
import { css, Global } from '@emotion/core'
import CirclePattern from '../../images/pattern-bar.svg'

const FooterStyles = withTheme(({ theme }) => (
  <Global
    styles={css`
      footer {
        &:before {
          content: '';
          display: block;
          width: 100%;
          height: 40px;
          background-size: cover;
          background-image: url(${CirclePattern});
          border-bottom: 4px solid ${theme.colors.tertiary.base.hex};

          @media (min-width: ${theme.breakpoints.lg}) {
            height: 148px;
          }
        }

        .top-section {
          border-bottom: none !important;
          background-color: #000000 !important;
        }
      }
    `}
  />
))

export default FooterStyles
