module.exports = [{
      plugin: require('../../../node_modules/gatsby-plugin-react-helmet-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteURL":"https://www.brightspeedplans.com"},
    },{
      plugin: require('../../../node_modules/@leshen/gatsby-theme-leshen/gatsby-browser.js'),
      options: {"plugins":[],"analyticsId":"GTM-MBNRXZD","brandTokens":["BSP","BSPB"],"mapiBrandToken":"BSP","siteName":"brightspeedplans","alternateName":"brightspeedplans.com","siteURL":"https://www.brightspeedplans.com","defaultTitleTemplate":"","defaultPhone":"8339644279","phoneSymbol":"-","defaultPromoCode":"161931","removeTrailingSlashes":true,"smartyStreetsWebsiteKey":"","addressQualificationCode":"","convertProjectID":"10045770","vwoProjectID":"894940","cobra":{"sitePath":"brightspeedplans.com","buttonLink":"/cart","buttonText":"Buy Now","buttonIcon":"Cart"}},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"brightspeedplans","short_name":"brightspeedplans","start_url":"/","background_color":"#ffc800","theme_color":"#ffc800","icon":"src/images/favicon.svg","display":"browser","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"8cc960dfc339fc1e180ac68d2244ebd4"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
