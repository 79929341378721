import * as React from 'react'
import { withTheme } from 'emotion-theming'
import { css, Global } from '@emotion/core'

const PriceStyles = withTheme(() => (
  <Global
    styles={css`
      .before {
        margin-bottom: 16px !important;
        font-size: 16px !important;
      }
    `}
  />
))

export default PriceStyles
